import React, { useEffect, useState } from 'react';
import { i18n } from 'app/utils/i18n';
import { useCreateLabel, useLabelsByKey, useUpdateLabel } from 'app/api/foundation/labels-queries';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { dispatchMessage, EventType } from 'app/useToasts';

const LabelEdit = ({ isNew = false }) => {
  const visibilityOptions = [
    { code: 'ANONYMOUS', value: i18n('label.visibility.ANONYMOUS') },
    { code: 'AUTHENTICATED', value: i18n('label.visibility.AUTHENTICATED') },
  ];

  const [key, setKey] = useState('');
  const [texts, setTexts] = useState({ sv: '', en: '' });
  const [visibility, setVisibility] = useState(visibilityOptions[1].code);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [busy, setBusy] = useState(false);

  const { id } = useParams();
  const history = useHistory();
  const { mutate: createLabel } = useCreateLabel(key, texts, visibility);
  const { mutate: updateLabel } = useUpdateLabel();
  const { data, refetch } = useLabelsByKey(id);

  useEffect(() => {
    if (id) {
      refetch();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      setKey(data[0].key);
      setVisibility(data[0].visibility);
      const texts = {};
      data.forEach((d) => {
        texts[d.locale] = d.text;
      });
      setTexts(texts);
    }
  }, [data]);

  function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitted(true);

    if (key && texts.sv && texts.en) {
      setBusy(true);

      if (isNew) {
        createLabel(
          { key, texts, visibility },
          {
            onSuccess: (data) => {
              if (data) {
                history.push(`/admin/labels/${key}`);
              }
            },
          }
        );

        dispatchMessage(EventType.ADD_MESSAGE, {
          type: 'success',
          title: i18n('label.create-new.success.title'),
          text: `${i18n('label.create-new.success.text')} ${key}`,
        });
      } else {
        const updatedLabels = data.map((d) => ({ ...d, text: texts[d.locale], key }));
        updateLabel(updatedLabels);
        dispatchMessage(EventType.ADD_MESSAGE, {
          type: 'success',
          title: i18n('label.update.success.title'),
          text: `${i18n('label.update.success.text')} ${updatedLabels.length ?? 0}`,
        });
      }
    }
  }

  return (
    <>
      <div className="content-title">
        <h1 className="">{i18n('label.title')}</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className={'col-form-label'} htmlFor="code">
              {i18n('label.key')}
            </label>
            <input className="form-control" name="key" id="key" type="text" value={key} onChange={(e) => setKey(e.target.value.trim())} />
            {isSubmitted && !key ? <div className="invalid-feedback d-block">Please enter a key.</div> : null}
          </div>

          <div className="mb-3">
            <label className={'col-form-label'} htmlFor="sv">
              {i18n('label.lang.sv')}
            </label>
            <input className="form-control" type="sv" name="sv" id="sv" value={texts.sv} onChange={(e) => setTexts({ ...texts, sv: e.target.value.trim() })} />
            {isSubmitted && !texts.sv ? <div className="invalid-feedback d-block">Please enter a swedish text.</div> : null}
          </div>

          <div className="mb-3">
            <label className={'col-form-label'} htmlFor="en">
              {i18n('label.lang.en')}
            </label>
            <input className="form-control" type="en" name="en" id="en" value={texts.en} onChange={(e) => setTexts({ ...texts, en: e.target.value.trim() })} />
            {isSubmitted && !texts.sv ? <div className="invalid-feedback d-block">Please enter an english text.</div> : null}
          </div>

          <div className="mb-3">
            <div>
              <label className={'col-form-label'} htmlFor="visiblity">
                {i18n('label.visiblity')}
              </label>
            </div>
            <select value={visibility} onChange={(e) => setVisibility(e.target.value)} id="visiblity">
              {visibilityOptions.map((o) => (
                <option key={o.code} value={o.code}>
                  {o.value}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-4 d-flex justify-content-end">
            <Link className="button clear" to="/admin/labels">
              {i18n('common.cancel')}
            </Link>
            <button className="button light-blue" type="submit" disabled={busy}>
              {i18n('common.save')}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default LabelEdit;
