import { Modal, LanguageSelector } from 'app/components';
import { useEffect, useState } from 'react';
import { i18n } from 'app/utils/i18n';
export const SelectLanguageModal = ({ title, show, onSubmit, onClose, currentLocale, confirmText }) => {
  const [selectedLocale, setSelectedLocale] = useState();
  useEffect(() => {
    setSelectedLocale(currentLocale);
  }, [currentLocale]);
  return (
    <Modal show={show} onClose={onClose}>
      <div className="header d-flex">
        <h3>{title}</h3>
        <div className="close" onClick={onClose}>
          <i className="fa-regular fa-xmark" />
        </div>
      </div>
      <div className="body ">
        <LanguageSelector locale={selectedLocale} onChange={setSelectedLocale} />
      </div>
      <div className="d-flex justify-content-end mt-4">
        <button onClick={onClose} type="button" className="btn">
          {i18n('common.cancel')}
        </button>
        <button
          type="button"
          onClick={() => {
            onSubmit(selectedLocale);
          }}
          className="btn btn-primary"
        >
          {confirmText}
        </button>
      </div>
    </Modal>
  );
};
