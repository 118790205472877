import { EditorContext } from 'app/state/contexts/EditorContext';
import { useContext } from 'react';
import { Element, Transforms, Node, Editor } from 'slate';
import { getBookmarkById } from './bookmark';
const isLink = (node) => Element.isElement(node) && node.type === 'link';

const withLinks =
  (options = {}) =>
  (editor) => {
    const { isInline, renderElement, normalizeNode } = editor;
    const { deleteLink, documentId } = options;
    editor.isInline = (element) => isLink(element) || isInline(element);
    editor.renderElement = (props) => {
      if (isLink(props.element)) {
        return <LinkElement {...props} editor={editor} />;
      } else {
        return renderElement(props);
      }
    };

    editor.normalizeNode = (entry) => {
      const [node, path] = entry;

      if (isLink(node)) {
        if (Node.string(node) === '') {
          const [linkElement] = Editor.node(editor, path);
          deleteLink({ id: linkElement.id, documentSuperId: documentId });
          Transforms.unwrapNodes(editor, { at: path });
          return;
        }
      }
      normalizeNode(entry);
    };
    return editor;
  };

export const navigateTo = (shadowHost, id) => {
  const elementId = `#${id}`;
  const foundElement = shadowHost.shadowRoot.querySelector(elementId);
  const y = foundElement?.getBoundingClientRect().top + window.scrollY - 160;
  window.scrollTo({ top: y, behavior: 'smooth' });
};

export const LinkElement = ({ attributes, children, element, editor }) => {
  const { shadowHost } = useContext(EditorContext);
  const { found } = getBookmarkById(editor, element.bookmarkId);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (found) {
      Transforms.select(editor, found.path);
    }
    navigateTo(shadowHost, element.bookmarkId);
  };
  return (
    <a
      {...attributes}
      id={element.id}
      href={shadowHost ? null : `#${element.bookmarkId}`}
      onClick={(e) => {
        shadowHost && handleClick(e);
      }}
      className="xrp-link"
    >
      {children}
    </a>
  );
};

export const insertLink = async (editor, linkId, bookmarkId, documentSuperId, sectionId) => {
  const link = { type: 'link', id: linkId, bookmarkId: bookmarkId, children: [] };
  Transforms.wrapNodes(editor, link, { split: true });
};

export const updateLinkBookmark = async (editor, linkId, bookmarkId) => {
  Transforms.setNodes(editor, { id: linkId, bookmarkId }, { match: (n) => n.type === 'link' });
};

export const getAllLinks = (editor) => {
  const links = [];

  for (const [node, path] of Node.nodes(editor, { reverse: false })) {
    if (node.type === 'link') {
      links.push({
        id: node.id,
        bookmarkId: node.bookmarkId,
        path,
        content: Node.string(node),
      });
    }
  }

  return links;
};

export const removeLink = async (id, editor) => {
  const [nodeEntry] = Editor.nodes(editor, {
    match: (node) => node.type === 'link' && node.id === id,
    at: [],
  });
  if (!nodeEntry) return;
  Transforms.unwrapNodes(editor, { at: nodeEntry[1] });
};

export const getLinkById = (editor, linkId) => {
  const links = [];

  for (const [node, path] of Node.nodes(editor, { reverse: false })) {
    if (node.type === 'link') {
      links.push({
        id: node.id,
        path,
        content: Node.string(node),
      });
    }
  }
  return { all: links, found: links.find((bm) => bm.id === linkId) };
};

export default withLinks;
