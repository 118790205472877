import { useMutation, useQuery, useQueryClient } from 'react-query';
import request from 'app/api/request';
import { errorHandler, showMessage } from 'app/utils/messages';
import { i18n } from 'app/utils/i18n';

const queryKeys = {
  image: 'image',
  images: 'images',
};

export const useImage = (revisionId) =>
  useQuery(
    [queryKeys.image, revisionId],
    async () => {
      if (!revisionId) {
        return null;
      }
      const response = await request.get(`/gaby/images/${revisionId}/thumbnail`);
      return { file: response.data.file, metadata: response.data.originalMetadata };
    },
    {
      onError: errorHandler,
    }
  );

export const useImages = (images = []) =>
  useQuery(
    [queryKeys.images, images],
    async () => {
      return await Promise.all(
        images.map(async (image) => {
          return request.get(`/gaby/images/${image.revisionId}/thumbnail`).then((res) => res.data);
        })
      );
    },
    {
      onError: errorHandler,
    }
  );

export const useChangeImageName = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ documentSuperId, imageSuperId, imageRevisionId, newName }) => {
      return request.post(`/gaby/documents/${documentSuperId}/actions`, {
        action: 'rename_image',
        superId: imageSuperId,
        revisionId: imageRevisionId,
        newName: newName,
      });
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([queryKeys.image, variables.imageRevisionId]);
        showMessage('success', i18n('image-queries.image-rename-success'));
      },
      onError: errorHandler,
    }
  );
};
